body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Top-Singo {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-height: 75vmax;
  background-color: white;
  margin-top: -120px;
  font-size: small;
}

.Singo-board1 {
  border-collapse: collapse;
  word-wrap: break-word;
}

.Singo-board1 td {
  border-style: solid;
  border-color: red;
  width: 80px;
  max-width: 80px;
  height: 80px;
  max-height: 80px;
  text-align: center;
  color: black;
}

.Singo-board2 {
  border-collapse: collapse;
  word-wrap: break-word;
  margin-top: 150px;
}

.Singo-board2 td {
  border-style: solid;
  border-color: lightgreen;
  width: 80px;
  max-width: 80px;
  height: 80px;
  max-height: 80px;
  text-align: center;
  color: black;
}

.Singo-title {
  text-align: center;
  break-before: page;
  background-color: white;
}

.Singo-title u {
  color: black;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  /* font-size: 100px; */
}
